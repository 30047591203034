import { getUserVouchers, voucherRemove } from "@/lib/jog";
import { VoucherStore } from "@jog/react-components";
import cartStore from "./cartStore";
const voucherStore = new VoucherStore({
    api: {
        getUserVouchers,
        voucherRemove,
    },
    store: {
        cartStore,
    },
});
export default voucherStore;
